import React, { Component } from 'react'
import { useNavigate } from "react-router-dom";
import "./form.css"
import axios from 'axios'

export default class Form extends Component {

    state = {
        name: '',
        lastname: '',
        email: '',
        sent: false,
        message: '',
        urgent: false,
        roofing: false,
        siding: false,
        trim: false,
        gutters: false,
        required: false,
        nameFocused: false,
        telFocused: false,
        emailFocused: false,
        radioFocused: false,
        modal: false,
        howHeard: '',
        submissionAttempted: false,
        isSending: false,
        submissionStatus: null, // Can be 'success', 'error', or null
    }

    handleName = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    handleTel = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
        let formattedNumber;
    
        // Check the length of the input to decide how to format it
        if (value.length <= 3) {
            formattedNumber = value;
        } else if (value.length <= 6) {
            formattedNumber = `${value.slice(0, 3)}-${value.slice(3)}`;
        } else {
            formattedNumber = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }
    
        this.setState({
            tel: formattedNumber
        });
    }
    

    handleEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    handleMessage = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    changeRoofing = (e) => {
        this.setState(prevState => ({
            roofing: !prevState.roofing,
        }))
    }

    changeSiding = (e) => {
        this.setState(prevState => ({
            siding: !prevState.siding,
        }))
    }

    changeTrim = (e) => {
        this.setState(prevState => ({
            trim: !prevState.trim,
        }))
    }

    changeGutters = (e) => {
        this.setState(prevState => ({
            gutters: !prevState.gutters,
        }))
    }

    changeUrgent = (e) => {
        this.setState(prevState => ({
            urgent: !prevState.urgent
        }))
    }

    handleNameFocus = (e) => {
        this.setState({
            nameFocused: true
        })
    }

    handleTelFocus = (e) => {
        this.setState({
            telFocused: true
        })
    }

    handleEmailFocus = (e) => {
        this.setState({
            emailFocused: true
        })
    }

    handleRadioFocus = (e) => {
        if (this.state.roofing === false && this.state.siding === false && this.state.trim === false && this.state.gutters === false) {
            this.setState({
                radioFocused: true
            })
        }
    }

    handleModal = (e) => {
        if (e.target.classList.contains("dismiss")) {
            this.setState({
                modal: false
            });
            
            const navigate = useNavigate();
            navigate("/");
        }
    }

    toggleModal = (show) => {
        this.setState({ modal: show });
    }

    handleChangeHowHeard = (e) => {
        this.setState({
            howHeard: e.target.value
        })
    }
    


    formSubmit = (e) => {
        e.preventDefault();

        console.log('form submission attempted')

        this.setState({ isSending: true, submissionStatus: null }); // Reset before sending
    
        // Custom validation logic
        let errors = [];
    
        // Example validation: check if 'name' field is not empty and is a valid pattern
        if (!this.state.name.trim() || !/^[A-Za-z\s]+$/.test(this.state.name)) {
            errors.push("Name is invalid.");
        }
    
        // Example validation: check if 'tel' field matches a specific pattern
        if (!/^\+?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(this.state.tel)) {
            errors.push("Phone number is invalid.");
        }
    
        // Example validation: check if 'email' field matches a specific pattern
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.state.email)) {
            errors.push("Email is invalid.");
        }
    
        // Check if 'howHeard' is selected (assuming it's required)
        if (!this.state.howHeard) {
            errors.push("Please select how you heard about us.");
        }
        
        // validation for service being selected.
        if (!this.state.roofing && !this.state.siding && !this.state.trim && !this.state.gutters) {
            errors.push("At least one service must be selected.");
            this.setState({ radioFocused: true }); // Indicate to the user that a service selection is required.
        } else {
            this.setState({ radioFocused: false }); // Reset the indication if a service is selected.
        }
    
        // Only proceed if there are no errors
        if (errors.length === 0) {
            let data = {
                name: this.state.name,
                tel: this.state.tel,
                email: this.state.email,
                message: this.state.message,
                roofing: this.state.roofing ? "Roofing" : "",
                siding: this.state.siding ? "Siding" : null,
                trim: this.state.trim ? "Trim" : null,
                gutters: this.state.gutters ? "Gutters" : null,
                urgent: this.state.urgent ? "Urgent" : null,
                howHeard: this.state.howHeard
            };
            console.log(data)

            this.setState({ isSending: true }); // Start sending

            axios.post('https://all-info-sites-api-bypgj.ondigitalocean.app/saconst/contact', data)
            // axios.post('http://localhost:6969/saconst/contact', data)
            .then(res => {
                // Assuming the submission is successful
                this.setState({
                    isSending: false,
                    submissionStatus: 'success',
                }, () => {
                    // Now that state is updated, decide to show the modal
                    this.toggleModal(true);
                });
            })
            .catch(error => {
                // Handle the error case
                console.error('Submission failed:', error);
                this.setState({
                    isSending: false,
                    submissionStatus: 'error',
                }, () => {
                    // Now that state is updated, decide to show the modal
                    this.toggleModal(true);
                });
            });
    
            this.setState(prevState => ({
                modal: !prevState.modal
            }));
        } else {
            // Handle validation errors
            // For example, you could set these errors into the state and display them in your render method
            console.error("Validation errors:", errors);
            // Optionally, toggle a state variable to show the errors in the UI
        }
    };
    

    resetForm = () => {
        this.setState({
            name: '',
            tel: '',
            email: '',
            message: '',
            roofing: false,
            siding: false,
            trim: false,
            gutters: false,
            urgent: false,
            required: false,
            nameFocused: false,
            telFocused: false,
            emailFocused: false,
            radioFocused: false,
            howHeard: '',
            submissionAttempted: false,
        })

        setTimeout(() => {
            this.setState({
                sent: false,
            })
        }, 200)
    }

    render() {
        return (
            <div className='container'>
                <div className='form--wrapper'>


                    {/* form logic */}
                    {/* if the form is not communicating with the backend */}
                    {!this.state.isSending &&
                            <form onSubmit={this.formSubmit}>
                               <div className='singleItem'>
                                   <input required={true} id="inputs" pattern="^([A-Za-z]{1,}([\.,] |[-']| ))+[A-Za-z]+\.?$" onBlur={this.handleNameFocus} focused={this.state.nameFocused.toString()} type='text' name='name' className='name' placeholder='Full Name'
                                       value={this.state.name}
                                       onChange={this.handleName}
                                   />
                                   <span>Please enter first and last name.</span>
                               </div>
       
                               <div className='singleItem'>
                                   <input required={true} type='tel' name='tel' className='name' placeholder='Phone Number' pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" onBlur={this.handleTelFocus} focused={this.state.telFocused.toString()}
                                       value={this.state.tel}
                                       onChange={this.handleTel}
                                   />
                                   <span>Must be a valid phone number.</span>
                               </div>
       
                               <div className='singleItem'>
                                   <input required={true} type='email' name='email' className='name' placeholder='Email' pattern="^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$" onBlur={this.handleEmailFocus} focused={this.state.emailFocused.toString()}
                                       value={this.state.email}
                                       onChange={this.handleEmail}
                                   />
                                   <span>Must be a valid email address.</span>
                               </div>
                               <h3>Select the required service(s)</h3>
       
                               <div className='selections'>
                                   <input type="radio" name="roofing" id="roofing" value="track" checked={this.state.roofing ? true : false} onClick={this.changeRoofing} onChange={this.handleRadioFocus} /><label htmlFor="track">Roofing</label>
       
                                   <input type="radio" name="siding" id="siding" value="event" checked={this.state.siding ? true : false} onClick={this.changeSiding} onChange={this.handleRadioFocus} /><label htmlFor="event">Siding</label>
       
                                   <input type="radio" name="trim" id="trim" value="message" checked={this.state.trim ? true : false} onClick={this.changeTrim} onChange={this.handleRadioFocus} /><label htmlFor="message">Trim</label>
       
                                   <input type="radio" name="gutters" id="gutters" value="message" checked={this.state.gutters ? true : false} onClick={this.changeGutters} onChange={this.handleRadioFocus} /><label htmlFor="message">Gutters</label><br />
       
                               </div>
                               <span className="radio--alert" style={this.state.radioFocused && this.state.roofing === false && this.state.siding === false && this.state.trim === false && this.state.gutters === false ? { display: "block" } : { display: "none" }}>One service must be selected.</span>
                               <div className='textArea singleItem'>
                                   <textarea name='message' id="" cols="30" rows="1" placeholder='Write A Message'
                                       value={this.state.message}
                                       onChange={this.handleMessage}
                                   ></textarea>
                               </div>
       
       
                               <h3>Is this an emergency?</h3>
                               <div className='selections'>
                                   <input 
                                       type="checkbox" 
                                       name="urgent" 
                                       id="urgent" 
                                       checked={this.state.urgent} 
                                       onChange={() => this.setState({ urgent: !this.state.urgent })} 
                                   /><label htmlFor="urgent">Flag as urgent</label><br />
                               </div>
                               <br></br>
       
                               <h3>How did you hear about us?</h3>
                               <div className='singleItem'>
                                   <select 
                                       className={this.state.submissionAttempted && !this.state.howHeard ? 'requiredField' : ''} 
                                       value={this.state.howHeard} 
                                       onChange={this.handleChangeHowHeard} 
                                       required
                                   >
                                       <option value="">Select...</option>
                                       <option value="Family/Friend">Family/Friend</option>
                                       <option value="Search Engine">Search Engine</option>
                                       <option value="Flyers">Flyers</option>
                                       <option value="Online Advertisement">Online Advertisement</option>
                                   </select>
                               </div>
       
       
                               <div className="btn">
                                   <button type="submit">Submit</button>
                               </div>
       
                           </form>
                    }


                    {/* if we are communicating with the backend */}
                    {this.state.isSending && 
                        <div className='loadingWrapper'>
                            <span class="loader"></span>
                        </div>
                    }
                    


                    {/* Modal Locic */}

                    {(this.state.submissionStatus === 'success' || this.state.submissionStatus === 'error') && 
                        <div style={this.state.modal ? { display: "block" } : { display: "none" }}>
                            <div className="overlaymodal dismiss" onClick={this.handleModal}>
                                <span className="dismiss" onClick={this.handleModal}>X</span>
                                <div className="modal--form">
                                    {this.state.submissionStatus === 'success' && (
                                        <>
                                            <h1>Success!</h1>
                                            <p>We will contact you shortly.</p>
                                        </>
                                    )}
                                    {this.state.submissionStatus === 'error' && (
                                        <>
                                            <h1>Submission Failed</h1>
                                            <p>There was an issue with your submission. Please try again later or call us at (269) 487-2319.</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </div>
        )
    }
}