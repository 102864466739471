import React from "react"
import "./footer.css"
import { NavLink, Link } from 'react-router-dom';

export default function Footer() {
    // Helper function to determine the class names
    const getNavLinkClass = ({ isActive }) => isActive ? "option active" : "option";
    const getNavLinkMobileClass = ({ isActive }) => isActive ? "optionmobile active" : "optionmobile";

    return (
        <div className="footer--container">
            <div className="footer--content">
                <div className="footer--nav--list">
                    <div className="link--container">
                        <NavLink className={getNavLinkClass} to='/privacypolicy'>Privacy Policy</NavLink>
                    </div>
                </div>
                <div className="footer--logo">
                    <Link to='/'>
                        <img className="footer--logo--img" src="/images/SACLogo.png" alt="SAC Logo" />
                    </Link>
                </div>
                <div className="siteby--container">
                    <NavLink className={getNavLinkClass} to='/termsconditions'>Terms & Conditions</NavLink>
                </div>
            </div>
            <div className="footermobile--content">
                <div className="footermobile--logo">
                    <Link to='/'>
                        <img className="footermobile--logo--img" src="/images/SACLogo.png" alt="SAC Mobile Logo" />
                    </Link>
                </div>
                <div className="footermobile--nav--list">
                    <div className="linkmobile--container">
                        <NavLink className={getNavLinkMobileClass} to='/privacypolicy'>Privacy Policy</NavLink>
                    </div>
                </div>
                <div className="sitebymobile--container">
                    <NavLink className={getNavLinkMobileClass} to='/termsconditions'>Terms & Conditions</NavLink>

                </div>
            </div>
        </div>
    );
}
