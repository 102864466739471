import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './index.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

// Use createRoot to mount your app
const container = document.getElementById('root');
const root = createRoot(container); // Create a root.

root.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
