import React, {useState} from "react"
import "./header.css"
import { NavLink , Link } from 'react-router-dom';


export default function Header(){
    const [open, setOpen] = useState(false);
    return (
        <div className="header--container">
            <div className="header--content">
                <div className="logo">
                    <Link to='/'>
                        <img className="logo--img" src="/images/SACLogo.png" alt="logo" />
                    </Link>
                </div>
                <div className="bg"></div>
                <div className={open ? "mobile--list" : "mobile--list2"}>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} onClick={() => setOpen(!open)} to='/'><p>Home</p></NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} onClick={() => setOpen(!open)} to='/aboutus'>About Us</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} onClick={() => setOpen(!open)} to='/services'>Services</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} onClick={() => setOpen(!open)} to='/projectgallery'>Project Gallery</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} onClick={() => setOpen(!open)} to='/contactus'>Contact Us</NavLink>                    
                </div>
                <div className="nav--list">
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} to='/'>Home</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} to='/aboutus'>About Us</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} to='/services'>Services</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} to='/projectgallery'>Project Gallery</NavLink>
                    <NavLink className={({ isActive }) => isActive ? "active option" : "option"} to='/contactus'>Contact Us</NavLink>                    
                </div>

                <div className={open ? 'menuToggle2' : 'menuToggle1'} onClick={() => setOpen(!open)}></div>
                
            </div>

        </div>
    )
}
