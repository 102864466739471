import React from "react"
import Terms from "../../components/terms/terms"

export default function TermsConditions(){
    return(
        <div className="termsconditions--container">
           <Terms />
        </div>
    )
}
