import React from "react"
import Privacy from "../../components/privacy/privacy"

export default function PrivacyPolicy(){
    return(
        <div className="privacy--container">
           <Privacy />
        </div>
    )
}
